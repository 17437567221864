import { Box, Flex, Grid } from "@chakra-ui/react";
import React, { useState, useEffect, useRef, createRef } from "react";
import { gsap } from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import styled from "styled-components";
import Close from "../assets/svg/close.inline.svg";
import Prophet from "../assets/svg/prophet.inline.svg";
import Nav from "../components/Nav";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Title = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 3rem;
	line-height: 0.94;
	text-align: left;
	color: #1a1818;
	text-transform: lowercase;
`;
const RichContent = styled.div`
	font-family: "Poppins";
	font-weight: 300;
	p {
		font-size: 1.25rem;
	}
	h4 {
		font-size: 2rem;
	}
	strong {
		font-weight: 300;
	}

	ol {
		margin-top: 1.5rem;
		list-style: none;
		counter-reset: mec-counter;
	}
	li {
		position: relative;
		counter-increment: mec-counter;
		margin: 0 0 0 20px;
		font-family: "Poppins";
		font-weight: 300;
		font-style: normal;
		font-size: 0.85rem;
	}
	li::before {
		position: absolute;
		content: counter(mec-counter) ". ";
		color: #1a1818;
		--size: 20px;
		width: var(--size);
		left: calc(-1 * var(--size));
		font-family: "Poppins";
		font-weight: 700;
		font-style: normal;
		font-size: 0.85rem;
	}
`;
const Modal = styled.div`
	position: absolute;
	top: 50px;
	min-width: 100px;
	min-height: 10px;
	visibility: hidden;
`;

const StyledClose = styled(Close)``;

const ModalTitle = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 1.5rem;
	line-height: 1;
	color: #1a1818;
`;

const ModalText = styled.article`
	p {
		font-family: "Poppins";
		font-weight: 300;
		font-size: 1.25rem;
	}
`;
export default function ModellingTemplate({ page }) {
	const breakpoints = useBreakpoint();
	const [currentBall, setCurrentBall] = useState(null);
	const [currentBallIndex, setCurrentBallIndex] = useState(null);
	//const content = createRef();
	const itemsRef = useRef([]);
	const prophet = createRef();
	const modal = createRef();
	const grid = createRef();

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(
			0,
			page.profetiaData.profetiaRepeater.length
		);
	}, [page.profetiaData.profetiaRepeater]);


	const click = (item, index) => {
		setCurrentBall(item);
		setCurrentBallIndex(index);
		const dropSelectedArr = itemsRef.current.filter((_, i) => i !== index);
		gsap.to(modal.current, { duration: 0.5, autoAlpha: 1 });
		if (breakpoints.sm) {
			gsap.to(grid.current, { duration: 0.5, autoAlpha: 0 });
		} else {
			gsap.to(prophet.current, { duration: 0.25, x: "-=70" });
			gsap.to(dropSelectedArr, { duration: 0.25, autoAlpha: 0 });
			gsap.to(itemsRef.current[index], {
				duration: 0.35,
				y: -(itemsRef.current[index].offsetTop - 120),
				x: -(itemsRef.current[index].offsetLeft - 120),
			});
		}
	};

	const close = () => {
		const dropSelectedArr = itemsRef.current.filter(
			(_, i) => i !== currentBallIndex
		);
		gsap.to(modal.current, { duration: 0.5, autoAlpha: 0 });

		if (breakpoints.sm) {
			gsap.to(grid.current, { duration: 0.5, autoAlpha: 1 });
		} else {
			gsap.to(prophet.current, { duration: 0.25, x: "+=70" });
			gsap.to(dropSelectedArr, { duration: 0.25, autoAlpha: 1 });
			gsap.to(itemsRef.current[currentBallIndex], {
				duration: 0.5,
				y: 0,
				x: 0,
			});
		}
	};

	const onMouseEnterHandler = (index) => {
		gsap.to(itemsRef.current[index], { duration: 0.15, scale: 1.1 });
		//menuTween.current.play();
	};
	const onMouseLeaveHandler = (index) => {
		gsap.to(itemsRef.current[index], { duration: 0.15, scale: 1 });
	};

	return (
		<>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Nav />
			<Box pos="relative" width="100%" maxW="1200px" mx="auto" px="16px">
				<Box
					ref={prophet}
					pos="absolute"
					top="-40px"
					left="-300px"
					display={["none", "none", "block"]}
					w={570}
				>
					<Prophet />
				</Box>
				<Box maxW={["auto","70%", 900]} w="100%" pt={[0, 0,90]} mx="auto">
					<Flex flexDirection={["column", "column", "row"]}>
						<Box pr={[0, 0, "40px"]} w={["100%","100%", "45%"]}>
							<Box
								pos="relative"
								w="50%"
								height="160px"
								display={["block", "block", "none"]}
								overflow="hidden"
							>
								<Box pos="absolute" top="0" w="100%">
									<Prophet />
								</Box>
							</Box>
							<Box
								borderRight={["none", "none", "3px solid #523178"]}
								borderTop={["3px solid #523178", "3px solid #523178", "none"]}
								pt={["0.5rem", "0.5rem", 0]}
								mb={["2rem", "2rem", 0]}
								pl={[0, 0, "2.5rem"]}
							>
								<Title>
									{page.frontpageLinkTitle.styledTitle ? page.frontpageLinkTitle.styledTitle : page.title}
								</Title>
							</Box>
						</Box>
						<Box pl={[0, 0, "40px"]} w={["100%", "100%", "55%"]}>
							<RichContent
								dangerouslySetInnerHTML={{
									__html: page.content,
								}}
							/>
						</Box>
					</Flex>
					<Box pos="relative" pl={[0, 0, "220px"]} mt={["0px", "10px", "6px"]}>
						<Grid
							templateColumns={[
								"repeat(2, 1fr)",
								"repeat(2, 1fr)",
								"repeat(3, 1fr)",
							]}
							gap={6}
							ref={grid}
						>
							{page.profetiaData.profetiaRepeater.map(
								(item, i) => (
									<Box cursor="pointer"
										key={`prophecy-${i}`}
										ref={(el) => (itemsRef.current[i] = el)}
										onClick={() => click(item, i)}
										onMouseEnter={() => onMouseEnterHandler(i)}
										onMouseLeave={() => onMouseLeaveHandler(i)}
									>
										<GatsbyImage
											image={
												item?.profetiaImage.localFile
													.childImageSharp.gatsbyImageData
											}
											style={{
												maxWidth: "400px",
												maxHeight: "100%",
											}}
											imgStyle={{ objectFit: "contain" }}
										/>
									</Box>
								)
							)}
						</Grid>
						<Modal ref={modal}>
							<Box pos={["relative", "relative", "absolute"]} top={0} width={"50px"}>
								<StyledClose onClick={close} />
							</Box>
							<Flex
								height={["auto","auto", "57px"]}
								//pb={[0, "2rem"]}
								pl={[0, 0, "135px"]}
								alignItems="center"
							>
								<Box
									display={["inline-block", "inline-block","none"]}
									w="40%"
									alignItems="center"
								>
								{currentBall ? 
									<GatsbyImage
										image={
											currentBall?.profetiaImage.localFile
												.childImageSharp.gatsbyImageData
										}
										style={{
											maxWidth: "400px",
											maxHeight: "100%",
										}}
										imgStyle={{ objectFit: "contain" }}
									/>
								: null }
								</Box>
								<ModalTitle>
									{currentBall?.profetiaHeading}
								</ModalTitle>
							</Flex>
							<Box
								maxWidth="600px"
								pl={[0, 0, 135]}
								mt={[0, 0, "2rem"]}
							>
								<ModalText
									dangerouslySetInnerHTML={{
										__html: currentBall?.profetiaText,
									}}
								></ModalText>
							</Box>
						</Modal>
					</Box>
				</Box>
			</Box>
		</>
	);
}
